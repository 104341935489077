/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require('@rails/ujs').start()
require('turbolinks').start()
require('jquery') // for cocoon
require('@nathanvda/cocoon') // for nested forms

import '@popperjs/core'
import * as bootstrap from 'bootstrap'
import '../stylesheets/application'
import AOS from 'aos'
import amplitude from 'amplitude-js'

document.addEventListener('DOMContentLoaded', function () {
  AOS.init({
    once: true,
  })
})
require('trix')
require('@rails/actiontext')

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual'
}

window.dataLayer = window.dataLayer || []
function gtag() {
  dataLayer.push(arguments)
}
gtag('js', new Date())

document.addEventListener('turbolinks:load', (event) => {
  gtag('config', 'G-PC7N8DKH56', { page_location: event.data.url })

  amplitude.getInstance().init('b13cb43ac10db04f7fa4bf79fe8acb45')
  const viewPosterBtns = document.querySelectorAll('.viewPosterBtn')
  viewPosterBtns.forEach((btn) => {
    btn.addEventListener('click', () => {
      amplitude.getInstance().logEvent('View Poster', {
        posterId: btn.id,
      })
      gtag('event', 'Download Poster', {
        posterId: btn.id,
      });
    })
  })

  const viewAbsBtns = document.querySelectorAll('.viewAbsBtn')
  viewAbsBtns.forEach((btn) => {
    btn.addEventListener('click', () => {
      amplitude.getInstance().logEvent('View Abstract', {
        posterId: btn.id,
      })
      gtag('event', 'Download Abstract', {
        posterId: btn.id,
      });
    })
  })

  const postEventModal = document.getElementById('postAnnouncement')
  if (postEventModal) {
    const myModal = new bootstrap.Modal(postEventModal)
    myModal.show()
  }
})
